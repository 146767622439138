<template>
  <div class="outbox">
    <component
      :is="currentView"
      :cardData="cardData"
      ref="coms"
      @playEnd="playNext()"
    ></component>
   
  </div>
</template>
<script>
import { GetParams2 } from "../../utils/common";
import ApiServe from "@/api/index";
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import page5 from "./page5.vue";
import page6 from "./page6.vue";
import page7 from "./page7.vue";
import page8 from "./page8.vue";
import page9 from "./page9.vue";
import page10 from "./page10.vue";
import page11 from "./page11.vue";
import page12 from "./page12.vue";
import page13 from "./page13.vue";
import page14 from "./page14.vue";
import page15 from "./page15.vue";
import page16 from "./page16.vue";
export default {
  name: "guojun",
  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
    page8,
    page9,
    page10,
    page11,
    page12,
    page13,
    page14,
    page15,
    page16
  },
  data() {
    return {
      audioSrcList: [],
      audioTimeList: [],
      device_id: null,
      recordtimeAll: null,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      audioData: [],
      currentIndex: 0,
      pageSortArr:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16],
      pageTimeArr:[],//记录每个页面的时间与旁白时间进行比较
      tempAduioArr:[],//记录接口给的初始旁白data
      aduioData: [], //旁白的数据
      cardData: {},
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
  },
  computed: {
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
    currentView() {
        let currentPage=this.pageSortArr[this.currentIndex]
      if (currentPage == 1) {
        return page1;
      }
       if (currentPage == 2) {
        return page2;
      }
      if (currentPage == 3) {
        return page3;
      }
      if (currentPage == 4) {
        return page4;
      }
       if (currentPage == 5) {
        return page5;
      }
      if (currentPage == 6) {
        return page6;
      }
      if (currentPage == 7) {
        return page7;
      }
       if (currentPage == 8) {
        return page8;
      }
      if (currentPage == 9) {
        return page9;
      }
      if (currentPage == 10) {
        return page10;
      }
       if (currentPage == 11) {
        return page11;
      }
       if (currentPage == 12) {
        return page12;
      }
       if (currentPage == 13) {
        return page13;
      }
      if (currentPage == 14) {
        return page14;
      }
       if (currentPage == 15) {
        return page15;
      }
       if (currentPage == 16) {
        return page16;
      }
      return page1;
    },
  },
  async mounted() {
    await this.getData()
    await this.getAudio() 
    if (this.ip && this.port) {
      const params = {
        ip: this.ip,
        port: this.port,
        offset_x: this.offset_x,
        offset_y: this.offset_y,
        width: this.width,
        height: this.height,
      };
      ApiServe.recordstartPc(this.newIpPort, params)
        .then(({ code }) => {
           this.load();
        })
        .catch((err) => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
     
    } else {
      // alert("没获取到ip,port");
      this.load();
    }
  },
  methods: {
    getData() {
      let params = {
        model_id: 59,
      };
      ApiServe.getDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.cardData = data
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },
     // 获取旁白
    getAudio() {
      let params = {
        model_id: 9
      }
      if(this.url_extra_params){
        params = {
          model_id: 9,
          url_extra_params:this.url_extra_params
        }
      }
      ApiServe.getVideoDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.tempAduioArr=data
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
          }
        });
    },
    setAudioData(audioArr,pageTimeArr){
      //  页面与旁白必须一对一
        let result=[]
        let timeIndex=0
        let len=audioArr.length
        for(let i=0;i<len;i++){
          let {audioLink}=audioArr[i]
            result.push({
            audioLink,
            timeIndex:timeIndex,
            audioLen:pageTimeArr[i]
            })
            //下一个起始需要加上总时长和专场的时间
            timeIndex+=pageTimeArr[i]+2
        }
        return result
    },
    endRecord() {
      this.aduioData=this.setAudioData(this.tempAduioArr,this.pageTimeArr)
      this.aduioData = [
        //page0
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-f1c50d84.mp3",
          timeIndex: 0,
          audioLen: 2,
        },
        //page1
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-ebf8f9a7.mp3",
          timeIndex: 5,
          audioLen: 12,
        },
         //page2
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-a7aadae4.mp3",
          timeIndex: 19,
          audioLen: 6,
        },
         //page3
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-d155d81b.mp3",
          timeIndex: 27,
          audioLen: 16,
        },
         //page4
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-6211896a.mp3",
          timeIndex: 45,
          audioLen: 9,
        },
         //page5
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-3b50cba7.mp3",
          timeIndex: 56,
          audioLen: 8,
        },
         //page6
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-c8f8eb90.mp3",
          timeIndex: 66,
          audioLen: 18,
        },
         //page7
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-bb04a220.mp3",
          timeIndex: 86,
          audioLen: 15,
        },
         //page8,page9
         {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-2a7f2147.mp3",
          timeIndex: 103,
          audioLen: 19,
        },
         //page10
         {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-ff61acc7.mp3",
          timeIndex: 126,
          audioLen: 8,
        },
        //page11
          {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-0eee9473.mp3",
          timeIndex: 136,
          audioLen: 12,
        }
        //page12
      ];
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: 155,
        videmBgm: "https://res.idtcdn.com/Cube/video-config/con-ec094cc4.mp3",
        audiosOrderList: this.aduioData,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    // 页面card一个接一个播放
    async load() {
      // this.$refs.bgAudio.volume = 0.1;
      // this.$refs.bgAudio.play();
      this.$refs.coms.playCardStart();
      //记录第一页的时间
      this.pageTimeArr.push( this.getCurrentPageTime())
      // 总时间 94 + 3秒
      if (this.ip) {
        setTimeout(() => {
          this.endRecord();
        }, 155*1000);
      }
    },
    async playNext() {
      //判断要不要延时再到下个页面
       let audioLen=Math.ceil(this.tempAduioArr[this.currentIndex])
        let pageLen=Math.ceil(this.$refs.coms.getTime()); 
        if(audioLen>pageLen){
            //延迟时间（内页穿出来的时间一定要是整数）
            await sleep((audioLen-pageLen)*1000);
        }
      // 记录每一页的时间
      this.currentIndex++;
      this.$nextTick(() => {
        this.$refs.coms.playCardStart();
        this.pageTimeArr.push( this.getCurrentPageTime())
      });
    },
    getCurrentPageTime(){
        let audioLen=this.tempAduioArr[this.currentIndex].audio_length
        let pageLen=this.$refs.coms.getTime()
        if(audioLen>pageLen){
            //旁白时间比页面时间长
            return Math.ceil(audioLen); 
      }else{
          return Math.ceil(pageLen); 
      }
    }
  },
};
</script>
<style lang="less">
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  .animate__animated {
  animation-fill-mode: both;
}
.outbox{
  background:#000;
  color:#fff;
  width:720px;
  height: 1280px;
}
.commonCartWrap{
  width:656px;
  height: 934px;
  position: absolute;
  left:32px;
  top:172px;
  background:#fff;
  padding:15px;
  font-size: 28px;
  opacity: 0;
  .tittle{
    color:#fff;
    font-size:30px;
    opacity: 0;
  }
  .copyRight{
    position: absolute;
    right:15px;
    bottom: 15px;
    color:#dfb29c;
    opacity: 0;
    font-size: 18px;
  }
}
</style>