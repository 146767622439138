<template>
   <div class="copyRight"> 
         <anime-txt
              id="copyRight"
              ref="copyRight"
              class="anime_txt"
              txt="本视频相关内容不做为基金宣传推介材料
数据来源：华安证券"
              duration="1000"
              easing="easeOutExpo"
              staggerSpeed="fast"
              inEffect="typeIn"
              outEffect="fadeOut"
            />
 
       
      </div>
</template>

<script>
import animeTxt from "@/components/animeTxt/index.vue";
import { sleep } from "@/utils/common";
export default {
  components: {
    animeTxt,
  },
  data: function () {
    return {
      
    };
  },
  methods: {
    // 第1页
    async play() {
      this.$refs.copyRight.playIn();
       await sleep(1000);
    },
  },
};
</script>

<style scoped lang="less">
.copyRight{
  position: absolute;
  width:350px;
  height: 56px;
  left: 185px;
  top: 1196px;

  .anime_txt{
        font-size: 20px;
          color:#fff;
  }
}
</style>