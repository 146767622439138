<template>
  <div class="main">
    <div class="card">
      <main-tittle
        ref="mainTittle"
        topTittle="湖北财经发展研究报告"
        subTittle="导读"
      ></main-tittle>
      <div class="commonCartWrap">
       
      </div>
      <main-foot
        ref="mainFoot"
      ></main-foot>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { sleep } from "@/utils/common";
import mainTittle from "./mainTittle.vue";
import mainFoot from "./mainFoot.vue";
export default {
  components: {
    mainTittle,
    mainFoot
  },
  data: function () {
    return {};
  },
  methods: {
    async playCardStart() {
      await this.$refs.mainTittle.play();
      $(".commonCartWrap").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);


      await this.$refs.mainFoot.play()
       this.$emit("playEnd");
    },
    getTime(){
      // 默认返回5秒
      return 5
    }
  },
};
</script>

<style lang="less">

</style>
